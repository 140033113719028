<template>
  <v-container>
    <v-form ref="form">
      <v-col cols="12">
        <v-row>
          <v-text-field
            v-model="referral.code"
            label="Referral Code"
            :rules="[v => !!v || 'Referral Code is required']"
          ></v-text-field>

          <v-btn @click="generateReferralCode()" color="primary">Generate Referral Code</v-btn>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="referral.start"
                label="Start Date"
                prepend-icon="event"
                readonly
                v-on="on"
                :rules="[v => !!v || 'Start Date is required']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="referral.start"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.menu.save(Date(date))">OK</v-btn>
            </v-date-picker>
          </v-menu>

          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="referral.expiry"
                label="Expiry Date"
                prepend-icon="event"
                readonly
                v-on="on"
                :rules="[v => !!v || 'Expiry Date is required']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="referral.expiry"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.menu2.save(Date(dates))">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-row>
      </v-col>

      <v-text-field
        v-model="referral.name"
        label="Name"
        :rules="[v => !!v || 'Name is required']"
      ></v-text-field>

      <v-select
        label="Project Name"
        :items="projects"
        item-text="name"
        item-value="_id"
        v-model="referral.projectId"
      ></v-select>

     <v-radio-group v-model="referral.browser_history">
      <template v-slot:label>
        <div>Browser History<strong> Available?</strong></div>
      </template>
      <v-radio value="true">
        <template v-slot:label>
          <div>This will make Browser History Available<strong class="success--text"> to the user</strong></div>
        </template>
      </v-radio>
      <v-radio value="false">
        <template v-slot:label>
          <div>Default Option: <strong class="primary--text">Browser History wont be available</strong></div>
        </template>
      </v-radio>
    </v-radio-group>

    </v-form>
    <v-btn class="ml-3" color="primary" @click="$router.push({ name: 'MobileProjectCodeIndex' })">Back</v-btn>
    <v-btn class="ml-3" color="primary" @click="create" v-if="type == 'CREATE'">Create</v-btn>
    <v-btn class="ml-3" color="primary" @click="update" v-if="type == 'UPDATE'">Update</v-btn>
  </v-container>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  props: {
    type: String,
    referral: Object,
  },
  data() {
    return {
      projects: [],
      date: null,
      dates: null,
      menu: false,
      menu2: false,
    };
  },

  mounted() {
    this.loadProjects();
  },

  methods: {
    create() {
      if (this.$refs.form.validate()) {
        this.$setLoader();

        service.createProjectCode(this.referral).then(res => {
          this.$showSuccessMessage(res.data)
          this.$router.push({ name: "MobileProjectCodeIndex" });
        })
      }
    },

    update() {
      if (this.$refs.form.validate()) {
        this.$setLoader();

        service.updateProjectCode(this.referral).then(res => {
          this.$showSuccessMessage(res.data)
        })
      }
    },

    generateReferralCode() {
      this.referral.code = Math.floor(100000 + Math.random() * 900000);
    },

    loadProjects() {
      service.fetchProjects().then(r => {
        this.projects = r.data;
      });
    }
  }
};
</script>
