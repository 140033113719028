<template>
  <v-card>
    <v-card-title>
      Edit Referral Code
    </v-card-title>

    <RewardForm 
      type="UPDATE"
      :referral="referral"
    />
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
import RewardForm from "./form.vue"

const service = new RestResource();
export default {
  components: {
    RewardForm
  },
  data() {
    return {
      referral: {
        code: null,
        created_by: "admin",
        updated_by: "admin",
        start: null,
        expiry: null,
      },
    };
  },
  mounted() {
    this.loadReferralCode()
  },
  methods: {
    loadReferralCode() {
      this.$setLoader();

      service.fetchProjectCodes({ _id: this.$route.params.id }).then(r => {
        this.referral = r.data[0];
        this.$disableLoader();
      });
    },
  }
};
</script>
